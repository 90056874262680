<template>
  <div v-if="bill" class="bill-detail">
    <PayWithCardDialog
      :bill="billToPay"
      :showDialog="showPaymentDialog"
      @close-dialog="showPaymentDialog = false"
    ></PayWithCardDialog>
    <TcVars />
    <PhysiCallDialog
      :physicallObj="physiCall"
      :triggerElement.sync="triggerElement"
    ></PhysiCallDialog>
    <FeedbackDialog
      :physicallObj="physiCall"
      :triggerElement.sync="triggerElement"
    />

    <v-dialog v-model="dialog.show" max-width="1140">
      <v-row>
        <v-col class="supply-dialog">
          <img
            @click="dialog.show = false"
            src="../../assets/ic_chevron_top_black.png"
          />
          <h2>{{ this.dialog.title }}</h2>
          <h4 v-html="this.dialog.text"></h4>
        </v-col>
      </v-row>
    </v-dialog>

    <v-row class="std-side-padding">
      <v-col>
        <v-breadcrumbs :items="breadcrumbsItems" divider=">" class="pb-0">
          <template v-slot:divider>
            <img src="@/assets/icons/chevron_rx_light.svg" width="24" />
          </template>
        </v-breadcrumbs>
      </v-col>
    </v-row>

    <v-row class="std-side-padding">
      <v-col>
        <v-row no-gutters class="bill-detail__row">
          <v-col class="col-10">
            <div class="bill-detail__title">
              {{ typeBill(bill) }} {{ formatdate(bill.dataInizioCompetenza) }}
            </div>
            <div>
              <span
                :class="
                  'bill-detail__status bill-detail__status--' +
                  bill.computedStatus
                "
                >{{ formatStatus(bill) }}</span
              >
              <h5
                class="bill-detail__warningPaying"
                v-if="billRequiresPayDisclamer(bill)"
              >
                Gestione in corso dalla banca con aggiornamento in
                {{ labelUpdatePaymentDelay(bill) }} giorni
              </h5>
              <span
                class="bill-detail__expiration"
                v-else-if="!bill.flagPagamentoRata"
                >Scadenza il {{ replaceslashDot(bill.dataScadenza) }}</span
              >
              <span class="bill-detail__rientro pl-3" v-else
                >Piano di rientro in corso</span
              >
            </div>
          </v-col>
          <v-col class="col-2" align="right">
            <v-img
              :src="getServiceIcon(bill.commodity)"
              height="84"
              width="84"
              contain
            ></v-img>
          </v-col>
        </v-row>
        <v-row no-gutters class="bill-detail__title"> Sintesi importi </v-row>
        <v-row no-gutters class="bill-detail__row">
          <v-col class="col-8 pr-8">
            <v-row
              v-if="bill.statoFattura !== 'Parziale'"
              class="bill-cost align-center"
              no-gutters
            >
              <v-col class="col-6 bill-cost__bold">
                <h3>Totale spesa</h3>
              </v-col>
              <v-col class="col-6 bill-cost__bold text-right">
                <h3>€ {{ replaceDecimalPointIta(totalCost()) }}</h3>
              </v-col>
            </v-row>
            <div v-else>
              <v-row class="bill-partial align-center" no-gutters>
                <v-col class="col-6 bill-partial bold">
                  <h3>Da pagare</h3>
                </v-col>
                <v-col class="col-6 bill-partial bold text-right">
                  <h3>
                    €
                    {{ replaceDecimalPointIta(bill.importoParzialeDaPagare) }}
                  </h3>
                </v-col>
              </v-row>
              <v-row class="bill-partial align-center" no-gutters>
                <v-col class="col-6 bill-partial">
                  <h5>Totale spesa</h5>
                </v-col>
                <v-col class="col-6 bill-partial bold text-right">
                  <span class="small">
                    € {{ replaceDecimalPointIta(bill.importo) }}
                  </span>
                </v-col>
              </v-row>
              <v-row class="bill-partial align-center" no-gutters>
                <v-col class="col-6 bill-partial">
                  <h5>Hai già pagato</h5>
                </v-col>
                <v-col class="col-6 bill-partial bold text-right">
                  <span class="small">
                    € {{ replaceDecimalPointIta(bill.importoParzialePagato) }}
                  </span>
                </v-col>
              </v-row>
            </div>
            <h3 class="cost-detail" v-if="bill.cost.length > 0">Dettaglio</h3>
            <v-row
              v-for="(cost, i) in arrayCost(bill.cost)"
              :key="i"
              :class="`bill-cost align-center ${
                arrayCost(bill.cost).length - 1 === i ? 'no-border' : ''
              }`"
              no-gutters
            >
              <v-col class="col-6 bill-cost__item">
                {{ getTicketItem(cost.item) }}
              </v-col>
              <v-col class="col-6 bill-cost__item text-right">
                <span> € {{ formatAmount(cost.amount) }} </span>
              </v-col>
            </v-row>
            <div class="bill-detail__box mt-7 mb-10">
              <h3>Informazioni</h3>
              <v-row no-gutters class="bill-info">
                <v-col cols="4">
                  <h6>Intestatario</h6>
                  <h6 class="capitalize">
                    <strong>{{ owner }}</strong>
                  </h6>
                </v-col>
                <v-col cols="4">
                  <h6>N° Fattura</h6>
                  <h5>
                    <strong>{{ bill.idFattura }}</strong>
                  </h5>
                </v-col>
                <v-col cols="4">
                  <h6>Emessa il</h6>
                  <h5>
                    <strong>{{
                      bill.dataEmissione.toString().replace(/\//g, '.')
                    }}</strong>
                  </h5>
                </v-col>
                <v-col cols="4" class="mt-7">
                  <h6>Indirizzo fornitura</h6>
                  <h5 class="capitalize">
                    <strong
                      >{{ bill.street.toLowerCase() }},
                      {{ bill.city.toLowerCase() }}</strong
                    >
                  </h5>
                </v-col>
                <v-col cols="4" class="mt-7">
                  <h6>Metodo di pagamento</h6>
                  <h5>
                    <strong>{{ getPaymentMethodLabel(bill.mdp) }}</strong>
                  </h5>
                </v-col>
              </v-row>
            </div>
            <div
              class="bill-detail__actions d-flex justify-space-between align-center"
            >
              <div class="bill-btn big pr-2">
                <router-link
                  :to="{
                    name: 'billsArchive',
                    params: {
                      clientOwnerCode: $route.params.clientOwnerCode,
                      supplyCode: $route.params.supplyCode,
                      valueTab: 'bollette'
                    }
                  }"
                  class="d-flex align-center justify-space-between"
                >
                  <h5 class="d-flex align-center">
                    <img class="mr-2" src="../../assets/icons/archive.svg" />
                    Riepilogo e archivio
                  </h5>
                  <img src="@/assets/icons/chevron_rx.svg" />
                </router-link>
              </div>
              <div
                class="bill-btn big pl-2"
                v-if="this.bill.commodity !== 'Fiber'"
              >
                <a
                  class="d-flex align-center justify-space-between"
                  href="#"
                  @click="
                    (event) => {
                      getBillPdf(
                        event,
                        bill.clientOwnerCode,
                        bill.idFattura,
                        'D'
                      ),
                        trackTapEvent({
                          name: 'billDetail_downloadDetail_tap',
                          params: getSupplyTrackingParams(detail)
                        })
                    }
                  "
                >
                  <h5 class="d-flex align-center">
                    <img class="mr-2" src="../../assets/icons/pdf.svg" />
                    Bolletta di dettaglio
                  </h5>
                  <img src="@/assets/ic_download_small_blue.svg" />
                </a>
              </div>
            </div>
          </v-col>
          <v-col class="col-4">
            <v-row class="bill-detail__guide">
              Vuoi capire meglio? Consulta la
              <a
                target="_blank"
                href="https://guidafattura.sorgenia.it/"
                @click="trackTapEvent('billDetail_billGuide_tap')"
                >Guida</a
              >
            </v-row>
            <v-row class="bill-detail__actions mb-6">
              <v-col class="bill-btn">
                <a
                  class="d-flex align-center justify-center"
                  href="#"
                  @click="
                    (event) => {
                      getBillPdf(
                        event,
                        bill.clientOwnerCode,
                        bill.idFattura,
                        'S'
                      ),
                        trackTapEvent({
                          name: 'billDetail_downloadSynthetic_tap',
                          params: getSupplyTrackingParams(detail)
                        })
                    }
                  "
                >
                  <h5>
                    {{
                      `Bolletta ${
                        this.bill.commodity !== 'Fiber' ? 'sintetica' : ''
                      }`
                    }}
                  </h5>
                  <img src="@/assets/icons/pdf.svg" class="ml-2" />
                </a>
              </v-col>
            </v-row>
            <v-row
              v-if="
                this.bill.consumptions.length > 0 &&
                this.bill.commodity !== 'Fiber'
              "
              no-gutters
              class="bill-detail__box flex-column mb-6"
            >
              <div class="bill-consumption mb-8">
                {{ formatConsumption() }} {{ getUnitMeasure(bill.commodity) }}
                <h5>Consumi fatturati</h5>
              </div>
              <div
                v-if="this.bill.commodity === 'Ele'"
                class="bill-consumption"
              >
                {{ calcKGCO2() }} Kg
                <h5>di CO2 risparmiati</h5>
              </div>
            </v-row>
            <v-row
              align="center"
              v-if="bill.showPaymentButton"
              no-gutters
              class="my-8"
            >
              <v-col>
                <v-btn
                  height="54"
                  color="primary"
                  class="v-btn--shadowed bill-detail__payment-button"
                  :rounded="true"
                  @click="openPaymentRoute(bill)"
                >
                  Paga o invia ricevuta
                </v-btn>
              </v-col>
            </v-row>
            <v-row
              no-gutters
              class="bill-detail__box"
              v-if="bill.selfReading && startSelfReading"
            >
              <div class="bill-selfReading">
                <h5 class="mb-6" v-if="startSelfReading.text">
                  {{ startSelfReading.text }}
                </h5>
                <div class="d-flex align-center justify-center">
                  <h6>
                    Ultima lettura inviata:
                    <span>{{
                      bill.selfReading &&
                      bill.selfReading.ultimalettura &&
                      bill.selfReading.ultimalettura.dataLettura
                        ? $filters.formatDateWithSeparator(
                            bill.selfReading.ultimalettura.dataLettura
                          )
                        : '-'
                    }}</span>
                  </h6>
                  <img
                    v-if="startSelfReading.info_popup"
                    @click="dataAlert(startSelfReading.info_popup)"
                    class="ml-2"
                    src="../../assets/icons/info.svg"
                  />
                </div>
                <h5
                  class="graph mt-6"
                  @click="$router.push({ name: startSelfReading.cta_action })"
                  v-if="
                    startSelfReading.cta &&
                    startSelfReading.status === 'ALLOWED'
                  "
                >
                  {{ startSelfReading.cta }}
                </h5>
                <h5
                  class="graph mt-6"
                  @click="dataAlert(startSelfReading.cta_action)"
                  v-if="
                    startSelfReading.cta &&
                    startSelfReading.status === 'THROUGH_CALLCENTER'
                  "
                >
                  {{ startSelfReading.cta }}
                </h5>
              </div>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
  <div v-else>
    <v-row class="std-side-padding pa-12" align="center">
      <v-col class="text-center">
        <v-progress-circular :size="50" indeterminate></v-progress-circular>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import PayWithCardDialog from '@/components/payments/PayWithCardDialog'
import TcVars from '@/components/tcTracker/TcVars'
import GenErr from '@/js/genericErrors'
import { getSupplyTrackingParams } from '@/js/trackingUtils'
import md5 from 'blueimp-md5'
import { mapActions, mapState } from 'vuex'
import consts from '@/js/constants'
import {
  CDN_URL,
  KGCO2_RATIO,
  computeBillPaymentDelay,
  computeBillStatusLabel,
  computeBillTitle,
  paymentTypeLabel
} from '../../js/constants'
import { capitalize, formatdateMonthYear } from '../../js/utils'

import PhysiCall from '../../js/service/physiCall'
import PhysiCallDialog from '../../components/physicall/PhysiCallDialog.vue'
import FeedbackDialog from '../../components/physicall/FeedbackDialog.vue'

let hasValueOneri = false

export default {
  name: 'billDetail',
  data() {
    return {
      bill: null,
      detail: {},
      dialog: {
        show: false,
        title: '',
        text: '',
        number: ''
      },
      showPaymentDialog: false,
      billToPay: null,
      getSupplyTrackingParams: getSupplyTrackingParams,
      billDetails: null,
      physiCall: null,
      triggerElement: null,
      consts: consts
    }
  },
  components: { PayWithCardDialog, TcVars, PhysiCallDialog, FeedbackDialog },
  computed: {
    ...mapState('session', ['isFeedbackShowing']),
    owner() {
      if (!this.bill.firstname || !this.bill.lastname) {
        return this.bill.ragioneSociale
      } else {
        return (
          this.bill.firstname.toLowerCase() +
          ' ' +
          this.bill.lastname.toLowerCase()
        )
      }
    },
    breadcrumbsItems() {
      let res = []
      if (this.detail && this.detail.address) {
        let address = this.detail ? this.detail.address : ''
        address = capitalize(address.toLowerCase())
        res = [
          { to: { name: 'home' }, text: 'Home' },
          { to: { name: 'ownerSupplies' }, text: 'Utenze', exact: true },
          {
            to: {
              name: 'supplyDetail',
              params: {
                clientOwnerCode: this.$route.params.clientOwnerCode,
                supplyCode: this.$route.params.supplyCode
              }
            },
            text: address,
            exact: true
          },
          { to: { name: 'bills' }, text: 'Bolletta' }
        ]
      }
      return res
    },
    startSelfReading() {
      this.trackTapEvent('billDetail_selfReading_tap')
      if (
        this.detail.selfReading &&
        this.detail.selfReading.availabilityStatus === 'NOT_NEEDED'
      ) {
        return {
          status: 'NOT_NEEDED',
          text: 'Autolettura non necessaria, consumi rilevati automaticamente',
          info_popup:
            this.detail.serviceType === 'Gas'
              ? 'Per questa fornitura i consumi vengono rilevati automaticamente. Se vuoi comunque avere ulteriori informazioni sulla gestione della lettura chiamaci al numero <strong>800.294.333</strong> (da fisso) oppure <strong>02.44.388.001</strong> (da mobile).'
              : '',
          cta: '',
          cta_action: ''
        }
      } else if (
        this.detail.selfReading &&
        this.detail.selfReading.availabilityStatus === 'THROUGH_CALLCENTER'
      ) {
        return {
          status: 'THROUGH_CALLCENTER',
          text: `Inviaci l'autolettura entro la prima settimana del mese`,
          info_popup: '',
          cta: 'Comunica autolettura ',
          cta_action:
            'Per questa fornitura puoi comunicarci la lettura contattando il nostro Servizio Clienti al numero <strong>800.294.333</strong> (da fisso) oppure <strong>02.44.388.001</strong> (da mobile).'
        }
      } else if (
        this.detail.selfReading &&
        this.detail.selfReading.availabilityStatus === 'ALLOWED'
      ) {
        return {
          status: 'ALLOWED',
          text: `Inviaci l'autolettura entro la prima settimana del mese`,
          info_popup: '',
          cta: 'Invia autolettura',
          cta_action: 'selfReadingLight_Base'
        }
      } else {
        return false
      }
    }
  },
  // eslint-disable-next-line vue/no-deprecated-destroyed-lifecycle
  beforeDestroy() {
    this.physiCall.clearTimer()
    window.removeEventListener('blur', this.handleWindowBlur)
    window.removeEventListener('focus', this.handleWindowFocus)
  },
  created() {
    this.physiCall = new PhysiCall(120000, null, 3)
    this.physiCall.setJourneySection(this.consts.JOURNEY.TIMER_BOLLETTA)
  },
  mounted() {
    window.addEventListener('blur', this.handleWindowBlur)
    window.addEventListener('focus', this.handleWindowFocus)
  },
  beforeCreate() {
    this.$store
      .dispatch('bill/getBill', {
        clientOwnerCode: this.$route.params.clientOwnerCode,
        supplyCode: this.$route.params.supplyCode,
        idFattura: this.$route.params.idFattura
      })
      .then(() => {
        this.bill =
          this.$store.getters['bill/billDetail'][this.$route.params.idFattura]

        this.$store
          .dispatch('supply/getDetail', {
            clientOwnerCode: this.$route.params.clientOwnerCode,
            supplyCode: this.$route.params.supplyCode
          })
          .then((detail) => {
            this.billDetails = detail.bills.filter(
              (item) => item.idFattura === this.$route.params.idFattura
            )[0]
          })
        this.getComputedBillStatus(this.bill).then((status) => {
          this.bill.computedStatus = status
          this.computeShowPaymentButton(this.bill).then((shouldShow) => {
            this.bill.showPaymentButton =
              shouldShow && parseFloat(this.bill.importo) > 0
            this.$forceUpdate()
          })
          this.$forceUpdate()
        })
      })
      .catch((error) => {
        GenErr.handleGenericError(error && error.message)
      })

    this.$store
      .dispatch('supply/getDetail', {
        clientOwnerCode: this.$route.params.clientOwnerCode,
        supplyCode: this.$route.params.supplyCode
      })
      .then((detail) => {
        this.detail = detail
        this.loaded = true
      })
      .catch((error) => {
        GenErr.handleGenericError(error && error.message)
      })
  },

  methods: {
    replaceDecimalPointIta: function (value) {
      if (value) {
        return Number(value).toLocaleString('it-IT', {
          minimumFractionDigits: 2
        })
      } else return 0
    },
    replaceslashDot: function (value) {
      if (value) {
        return value.toString().replace(/\//g, '.')
      } else {
        return ''
      }
    },
    ...mapActions('bill', [
      'getComputedBillStatus',
      'checkBillPaid',
      'computeShowPaymentButton'
    ]),
    ...mapActions('analytics', ['trackTapEvent']),
    arrayCost(billCost) {
      let array = [
        'spesaMateriaEnergia',
        'spesaTrasporto',
        'spesaOneri',
        'altrePartite',
        'altrePartiteFibra',
        'ricalcoliFibra',
        'ivaImposte',
        'altrePartiteFibra',
        'canoneRai',
        'canoneFibra',
        'Sconto',
        'bonusSociale'
      ]
      if (billCost.ricalcolo) {
        array.splice(5, 0, 'ricalcoli')
      }
      let arrayCost = []
      array.forEach(function (_cost) {
        if (billCost.array) {
          let billItem = billCost.array.find((cost) => cost.item === _cost)
          if (billItem && billItem.item === 'spesaOneri') {
            hasValueOneri = true
          }
          if (billCost.tipo !== 'fattura manuale') {
            if (
              _cost === 'spesaMateriaEnergia' ||
              _cost === 'spesaTrasporto' ||
              (_cost === 'spesaOneri' && hasValueOneri) ||
              _cost === 'ivaImposte' ||
              _cost === 'altrePartite'
            ) {
              arrayCost.push({
                item: _cost,
                amount: billItem ? billItem.amount : 0
              })
            } else {
              if (billItem) {
                if (parseFloat(billItem.amount) !== 0) {
                  arrayCost.push({
                    item: _cost,
                    amount: billItem.amount
                  })
                }
              }
            }
          } else {
            if (billItem) {
              if (parseFloat(billItem.amount) !== 0) {
                arrayCost.push({
                  item: _cost,
                  amount: billItem.amount
                })
              }
            }
          }
        }
      })
      return arrayCost
    },
    openPaymentRoute(bill) {
      this.$router.push({
        name: 'payBill',
        params: {
          bill: this.billDetails,
          isMultiPayment: false,
          idFattura: bill.idFattura,
          from: this.$route.name
        }
      })
    },
    typeBill(bill) {
      return computeBillTitle(bill)
    },
    formatdate(data) {
      return formatdateMonthYear(data)
    },
    formatStatus(bill) {
      let mdp = bill.mdp.toUpperCase()
      let isRecurrent = ['CCR', 'SDD', 'DPAY'].includes(mdp)
      if (isRecurrent && bill.computedStatus === '1') {
        return 'In pagamento'
      } else if (
        bill.statoFattura === 'Parziale' &&
        bill.computedStatus === '3'
      ) {
        return 'PAGATA PARZIALMENTE'
      } else {
        return computeBillStatusLabel(bill.computedStatus)
      }
    },
    getServiceIcon(commodity) {
      if (commodity === 'Gas')
        return require('../../assets/icons/ic-fire-blu.svg')
      if (commodity === 'Ele')
        return require('../../assets/icons/ic-light-blu.svg')
      if (commodity === 'Fiber')
        return require('../../assets/icons/ic-wifi-blu.svg')
    },
    totalCost() {
      if (this.bill.importo) {
        return this.bill.importo
      } else if (this.bill.cost.array && this.bill.cost.array.length > 0) {
        let cost = this.bill.cost.array
        let costFinder = cost.find((cost) => cost.item === 'importo')
        if (costFinder) {
          return parseFloat(costFinder.amount).toFixed(2)
        } else {
          return cost.reduce((acc, cost) => {
            if (
              parseFloat(cost.amount) !== 0 &&
              cost.item !== 'consumoTotaleFatturato'
            ) {
              return (parseFloat(acc) + parseFloat(cost.amount)).toFixed(2)
            } else {
              return acc
            }
          }, 0)
        }
      } else {
        return 0
      }
    },
    formatAmount(value) {
      return parseFloat(value).toFixed(2).toString().replace('.', ',')
    },
    formatConsumption() {
      if (this.bill.consumptions[0]) {
        return parseFloat(this.bill.consumptions[0].consumo)
          .toFixed(1)
          .toString()
          .replace('.', ',')
      } else {
        return '-,-'
      }
    },
    getUnitMeasure(value) {
      return value === 'Ele' ? 'kWh' : 'Smc'
    },
    calcKGCO2() {
      if (this.bill.consumptions[0]) {
        return (parseFloat(this.bill.consumptions[0].consumo) * KGCO2_RATIO)
          .toFixed(1)
          .toString()
          .replace('.', ',')
      } else {
        return '-,-'
      }
    },
    getPaymentMethodLabel(mdp) {
      return paymentTypeLabel(mdp)
    },
    dataAlert(text, number) {
      this.dialog = { show: true, title: 'Lettura', text: text, number: number }
    },
    getTicketItem(value) {
      switch (value) {
        case 'importo':
          return 'Totale spesa'
        case 'spesaMateriaEnergia':
          return this.bill.commodity === 'Gas'
            ? 'Materia gas naturale'
            : 'Materia energia'
        case 'spesaTrasporto':
          return hasValueOneri
            ? 'Trasporto e gestione'
            : 'Trasporto, gestione e oneri'
        case 'spesaOneri':
          return 'Oneri di sistema'
        case 'ivaImposte':
          return 'IVA e imposte'
        case 'altrePartite' || 'altrePartiteFibra':
          return 'Altre partite'
        case 'ricalcoli' || 'ricalcoliFibra':
          return 'Ricalcoli'
        case 'canoneRai':
          return 'Canone Rai'
        case 'bonusSociale':
          return 'Bonus sociale'
        case 'canoneFibra':
          return 'Canone'
        case 'scontoFibra':
          return 'Sconto'
      }
    },
    billRequiresPayDisclamer(bill) {
      return this.labelUpdatePaymentDelay(bill) !== null
    },
    labelUpdatePaymentDelay(bill) {
      return computeBillPaymentDelay(bill)
    },
    getBillPdf(event, clientOwnerCode, idFattura, type) {
      this.triggerElement = event
      this.physiCall.setJourneyDoc(this.consts.JOURNEY.DOWNLOAD_BOLLETTA)
      let isPhysicallShowing = this.physiCall.checkCountClick(type, true)
      if (!isPhysicallShowing && !this.isFeedbackShowing) {
        let companyPrefix = 'SRG'
        let dataParams = md5(
          companyPrefix + '/' + clientOwnerCode + '/' + idFattura + '/' + type
        )
        let pdfUrl = CDN_URL + '/' + dataParams

        window.open(pdfUrl, '_blank')
      } else {
        return false
      }
    },

    openPaymentDialog(bill) {
      this.billToPay = bill
      this.showPaymentDialog = true
    },
    handleWindowBlur() {
      this.physiCall.resetAll()
    },
    handleWindowFocus() {
      if (this.physiCall) {
        this.physiCall.resetAll()
      }
      this.physiCall = new PhysiCall(120000, null, 3)
      this.physiCall.setJourneySection(this.consts.JOURNEY.TIMER_BOLLETTA)
    }
  }
}
</script>
<style lang="scss" scoped>
.bill-detail {
  &__title {
    font-size: 40px;
    font-weight: 500;
    letter-spacing: 0.8px;
    margin-bottom: 8px;
    line-height: 1.3;
  }
  &__row {
    margin-bottom: 50px;
    .bill-cost {
      padding: 16px 0 16px 0;
      border-bottom: 1px solid #eaeef5;
      &.no-border {
        border-bottom: initial;
      }
      &__bold {
        font-weight: bold;
        h3 {
          font-size: 28px;
          font-weight: bold;
          letter-spacing: 1.2px;
        }
      }
      &__item {
        font-size: 16px;
        letter-spacing: 1px;
        color: black;
        line-height: 1.4;
        span {
          font-size: 24px;
          font-weight: bold;
          line-height: 1.2;
          letter-spacing: 0.48px;
        }
      }
    }
    .bill-partial {
      padding: 5px 0px 5px 0;
      &.bold {
        font-weight: bold;
        h3 {
          font-weight: bold;
        }
        span {
          &.small {
            font-size: 24px;
          }
        }
      }
    }
  }
  .cost-detail {
    margin-top: 20px;
    padding-bottom: 30px;
    padding-right: 0px;
    font-weight: bold;
    border-bottom: 1px solid #eaeef5;
  }
  &__box {
    border-radius: 20px;
    background: #f8f9fc;
    padding: 24px;
    margin-bottom: 24px;
    h3 {
      font-size: 24px;
      font-weight: 500;
      line-height: 1.2;
      letter-spacing: 0.48px;
      margin-bottom: 22px;
    }
    .bill-consumption {
      font-size: 32px;
      font-weight: bold;
      line-height: 1.3;
      letter-spacing: 1.2px;
      color: black;
      h5 {
        line-height: 1.4;
        letter-spacing: 1px;
      }
      &:last-child {
        margin-bottom: 0px;
      }
    }

    .bill-selfReading {
      h5 {
        color: black;
        line-height: 1.4;
        letter-spacing: 1px;
        cursor: pointer;
        &.graph {
          font-size: 18px;
          font-weight: bold;
          line-height: 1.4;
          letter-spacing: 1.2px;
          text-align: center;
          color: $srg-blue;
          &::after {
            content: url('../../assets/icons/graph.svg');
            margin-left: 8px;
          }
        }
      }
      h6 {
        color: black;
        font-size: 14px;
        line-height: 1.3;
        letter-spacing: 1px;
      }
      span {
        font-size: 16px;
        font-weight: bold;
        line-height: 1.4;
      }
    }
    .bill-info {
      color: black;
      h6 {
        font-size: 14px;
        line-height: 1.3;
        letter-spacing: 1px;
        margin-bottom: 8px;
        &.capitalize {
          text-transform: capitalize;
        }
      }
    }
  }
  &__payment-button.v-btn {
    width: 100%;
  }
  &__guide {
    margin-top: 16px;
    font-size: 14px;
    color: black;
    line-height: 1.3;
    letter-spacing: 1px;
    margin-bottom: 24px;
    display: flex;
    justify-content: center;
    a {
      margin-left: 5px;
      color: $srg-blue;
      font-weight: bold;
      line-height: 1.3;
      cursor: pointer;
    }
  }
  &__actions {
    .bill-btn {
      border: 1.08px solid #dbdbdb;
      border-radius: 20px;
      height: 54px;
      padding: 0 16px;
      cursor: pointer;
      a {
        height: 100%;
      }
      h5 {
        font-size: 18px;
        line-height: 1.4;
        letter-spacing: 1.2px;
        font-weight: bold;
        color: $srg-blue;
      }
      &:last-child {
        margin-right: 0px;
      }
      &.big {
        width: 350px;
      }
    }
  }
  &__status {
    font-size: 22px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    &--1 {
      color: $srg-blue;
      margin-right: 10px;
    }
    &--2 {
      color: $srg-purple;
      margin-right: 10px;
    }
    &--3 {
      color: $srg-purple;
      margin-right: 10px;
    }
    &--4,
    &--NA {
      color: black;
      margin-right: 10px;
    }
    &--5,
    &--6 {
      color: $srg-blue;
      margin-right: 10px;
    }
  }
  &__expiration {
    font-size: 16px;
    font-weight: 300;
  }
  &__rientro {
    font-size: 16px;
    font-weight: normal;
    color: #d93273;
  }
  &__navbar {
    display: flex;
    align-content: center;
    margin-bottom: 14px;
    padding-left: 12px;
    margin-top: 50px;
    h5 {
      color: black;
      &.capitalize {
        text-transform: capitalize;
      }
      &.bold {
        font-weight: 500;
      }
    }
    img {
      margin: 0 10px;
    }
  }
  &__warningPaying {
    color: black;
    display: inline-block;
    letter-spacing: 1px;
  }
}
</style>

<style lang="scss">
.v-dialog__content {
  .supply-dialog {
    background: white;
    padding: 55px 155px 55px 55px;
    border-radius: 40px;
    position: relative;
    h2 {
      font-weight: bold;
      margin-bottom: 20px;
    }
    h4 {
      span {
        font-weight: bold;
      }
    }
    img {
      position: absolute;
      top: 40px;
      right: 40px;
      cursor: pointer;
    }
  }
}
</style>
