import { axiosACN } from '@/js/axiosInstances.js'
import Consts from '@/js/constants'
import GenErr from '@/js/genericErrors'
import axios from 'axios'
import { get } from 'lodash'

const getUseTypeGas = (useType) => {
  let use = ''
  if (useType.useToCook) {
    use = 'COT'
    if (useType.useToHotWater) use = 'COAC'
    if (useType.useToHeating) use = 'RICO'
    if (useType.useToHeating && useType.useToHotWater) use = 'RCAC'
  } else if (useType.useToHotWater) {
    use = 'AC'
    if (useType.useToHeating) use = 'RIAC'
  } else if (useType.useToHeating) {
    use = 'RISC'
  }
  return use
}

export default {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {
    client: null,
    commodityType: null, // ele, gas
    typeRequest: null,
    flowType: null, // migration, reactivation, new
    estimationType: null, // manual, bill, nobill
    paymentMethod: null,
    mgmDetail: null,
    isComplete: false,
    currentStep: null,
    upselling_session_id: null,
    address: {
      clientOwnerCode: null,
      address: null,
      egonLocality: null,
      egonRegion: null,
      egonStreet: null,
      egonStreetNumber: null,
      streetNumber: null,
      egonStreetNumberLetter: null,
      comune: null,
      comuneEgon: null,
      codComune: null,
      provincia: null,
      zip: null
      // dati clienti, + egon, + indirizzo testuale
    },
    offer: {},
    exportedQuote: {},
    consens: false,
    supplyDetail: {
      contractCode: null,
      selectedOffer: null,
      isOwner: null,
      isResident: null,
      isPrimaryHome: null,
      oldProvider: null,
      isUnpluggable: null,
      ownership: null, // owner, usufrutto, affitto, other
      anticipateActivation: null,
      dac: null,
      fiscalCode: null,
      ele: {
        pod: null,
        power: null,
        alternativePower: null,
        consumption: null,
        avgBillCost: null,
        tension: null,
        powerChanged: null,
        isRes: null,
        rooms: null, // number of rooms
        waterHeatintMode: null,
        homeHeatingMode: null
      },
      gas: {
        avgBillCost: null,
        consumption: null,
        remi: null,
        pdr: null,
        useToCook: false,
        useToHotWater: false,
        useToHeating: false,
        rooms: null, // number of rooms,
        waterHeatintMode: null,
        homeHeatingMode: null
      }
    },
    validationErrors: [],
    infoUpselling: {},
    supplyUpselling: {},
    flagNewAddress: false,
    flagAddedAddress: false,
    idTariffList: null // --> M CAMPO DA MODIFICARE IN BASE AL MODE DATO DAL BACKEND
  },
  getters: {
    client: (state) => state.client,
    address: (state) => state.address,
    commodityType: (state) => state.commodityType,
    upsellingSessionId: (state) => state.upselling_session_id,
    typeRequest: (state) => state.typeRequest,
    flowType: (state) => state.flowType,
    estimationType: (state) => state.estimationType,
    offer: (state) => state.offer,
    supplyDetail: (state) => state.supplyDetail,
    currentStep: (state) => state.currentStep,
    exportedQuote: (state) => state.exportedQuote,
    paymentMethod: (state) => state.paymentMethod,
    consens: (state) => state.consens,
    mgmDetail: (state) => state.mgmDetail,
    getUseTypeGas: (state) => getUseTypeGas(state.supplyDetail.gas),
    validationErrors: (state) => state.validationErrors,
    getInfoUpselling: (state) => state.infoUpselling,
    supplyUpselling: (state) => state.supplyUpselling,
    getFlagNewAddress: (state) => state.flagNewAddress,
    getFlagAddedAddress: (state) => state.flagAddedAddress,
    getIdTariffList: (state) => state.idTariffList // --> M CAMPO DA MODIFICARE IN BASE AL MODE DATO DAL BACKEND
  },
  mutations: {
    setCurrentStep(state, currentStep) {
      state.currentStep = currentStep
    },
    setSelectedOffer(state, offer) {
      state.supplyDetail.selectedOffer = offer
    },
    setUpsellingSessionId(state, uuid) {
      state.upselling_session_id = uuid
    },
    setClient(state, client) {
      state.client = client
    },
    setCommodityType(state, type) {
      state.commodityType = type
    },
    setTypeRequest(state, typeRequest) {
      state.typeRequest = typeRequest
    },
    setFlowType(state, type) {
      state.flowType = type
    },
    setEstimationType(state, type) {
      state.estimationType = type
    },
    setOffer(state, result) {
      state.offer = result
    },
    setSupplyDetailRoot(state, props) {
      const definedProps = (obj) =>
        Object.fromEntries(
          Object.entries(obj).filter(([k, v]) => v !== undefined)
        )
      Object.assign(state.supplyDetail, definedProps(props))
    },
    setSupplyDetail(state, props) {
      const definedProps = (obj) =>
        Object.fromEntries(
          Object.entries(obj).filter(([k, v]) => v !== undefined)
        )
      Object.assign(
        state.supplyDetail[state.commodityType],
        definedProps(props)
      )
    },
    updateAddress(state, props) {
      const definedProps = (obj) =>
        Object.fromEntries(
          Object.entries(obj).filter(([k, v]) => v !== undefined)
        )
      Object.assign(state.address, definedProps(props))
    },
    setExportedQuote(state, exportedQuote) {
      state.exportedQuote = exportedQuote
    },
    setPaymentMethod(state, method) {
      state.paymentMethod = method
    },
    setConsens(state, consens) {
      state.consens = consens
    },
    setMgmDetail(state, mgmDetail) {
      state.mgmDetail = mgmDetail
    },
    resetSupplyDetail(state) {
      let oldOwnerValue = state.supplyDetail.isOwner
      let oldResidentValue = state.supplyDetail.isResident
      let oldSelectedOffer = state.supplyDetail.selectedOffer
      state.estimationType = null
      state.supplyDetail = {
        contractCode: null,
        selectedOffer: oldSelectedOffer,
        isOwner: oldOwnerValue,
        isResident: oldResidentValue,
        isPrimaryHome: null,
        oldProvider: null,
        isUnpluggable: null,
        ownership: null, // owner, usufrutto, affitto, other
        anticipateActivation: null,
        dac: null,
        fiscalCode: null,
        ele: {
          pod: null,
          power: null,
          alternativePower: null,
          consumption: null,
          avgBillCost: null,
          tension: null,
          powerChanged: null,
          isRes: null,
          rooms: null, // number of rooms
          waterHeatintMode: null,
          homeHeatingMode: null
        },
        gas: {
          avgBillCost: null,
          consumption: null,
          remi: null,
          pdr: null,
          useToCook: false,
          useToHotWater: false,
          useToHeating: false,
          rooms: null, // number of rooms,
          waterHeatintMode: null,
          homeHeatingMode: null
        }
      }
    },
    resetUpsellingComm(state) {
      state.client = null
      state.commodityType = null
      state.typeRequest = null
      state.flowType = null
      state.estimationType = null
      state.paymentMethod = null
      state.mgmDetail = null
      state.isComplete = false
      state.currentStep = null
      state.upselling_session_id = null
      state.idTariffList = null // --> CAMPO DA MODIFICARE IN BASE AL NOME DATO DAL BACKEND
      state.supplyUpselling = {}
      state.address = {
        clientOwnerCode: null,
        address: null,
        egonLocality: null,
        egonRegion: null,
        egonStreet: null,
        egonStreetNumber: null,
        streetNumber: null,
        egonStreetNumberLetter: null,
        comune: null,
        comuneEgon: null,
        codComune: null,
        provincia: null,
        zip: null
        // dati clienti, + egon, + indirizzo testuale
      }
      state.offer = {}
      state.exportedQuote = {}
      state.consens = false
      state.supplyUpselling = {}
      state.supplyDetail = {
        contractCode: null,
        selectedOffer: null,
        isOwner: null,
        isResident: null,
        isPrimaryHome: null,
        oldProvider: null,
        isUnpluggable: null,
        ownership: null, // owner, usufrutto, affitto, other
        anticipateActivation: null,
        dac: null,
        fiscalCode: null,
        ele: {
          pod: null,
          power: null,
          alternativePower: null,
          consumption: null,
          avgBillCost: null,
          tension: null,
          powerChanged: null,
          isRes: null,
          rooms: null, // number of rooms
          waterHeatintMode: null,
          homeHeatingMode: null
        },
        gas: {
          avgBillCost: null,
          consumption: null,
          remi: null,
          pdr: null,
          useToCook: false,
          useToHotWater: false,
          useToHeating: false,
          rooms: null, // number of rooms,
          waterHeatintMode: null,
          homeHeatingMode: null
        }
      }
    },
    setValidationErrors(state, error) {
      if (!state.validationErrors.includes(error)) {
        state.validationErrors.push(error)
      }
    },
    deleteValidationError(state, error) {
      let index = state.validationErrors.indexOf(error)
      index !== -1 && state.validationErrors.splice(index, 1)
    },
    resetValidationErrors(state) {
      state.validationErrors = []
    },
    setInfoUpselling(state, payload) {
      state.infoUpselling = payload
    },
    setSupplyUpselling(state, payload) {
      state.supplyUpselling = payload
    },
    setFlagNewAddress(state, payload) {
      state.flagNewAddress = payload
    },
    setFlagAddedAddress(state, payload) {
      state.flagAddedAddress = payload
    },
    setIdTariffList(state, payload) {
      state.idTariffList = payload // --> M CAMPO DA MODIFICARE IN BASE AL MODE DATO DAL BACKEND
    }
  },
  actions: {
    async retrieveInfoUpselling({ rootGetters, commit }, params) {
      const response = await axiosACN.post(
        'commodity/retrieveProductDetail',
        params,
        {
          headers: {
            Authorization: 'Bearer ' + rootGetters['session/token']
          }
        }
      )
      if (response.data.result) {
        commit('setInfoUpselling', response.data.result[0])
      } else {
        throw Error('NO_DATA_RESULT')
      }
    },
    checkIBAN({ rootGetters }, iban) {
      const abi = iban.substring(5, 10)
      const cab = iban.substring(10, 15)
      if (iban.length < 20 || !abi || !cab) {
        return false
      }
      return axiosACN
        .post(
          '/sorgeniadataclient/isvalidabicab',
          {
            abi: abi,
            cab: cab
          },
          {
            headers: {
              Authorization: 'Bearer ' + rootGetters['session/token']
            }
          }
        )
        .then((res) => {
          return res.data.status === 'OK'
        })
        .catch((err) => {
          if (err.statusCode && err.statusCode >= 400) {
            throw Error('SERVER_ERROR')
          }
        })
    },
    checkPOD({ rootGetters }, pod) {
      return axiosACN
        .post(
          '/commodity/doValidatePod',
          {
            pod: pod
          },
          {
            headers: {
              Authorization: 'Bearer ' + rootGetters['session/token']
            }
          }
        )
        .then((res) => {
          return res.data.status === 'OK'
        })
        .catch((err) => {
          if (err.statusCode && err.statusCode >= 400) {
            throw Error('SERVER_ERROR')
          }
        })
    },
    checkPDR({ rootGetters }, pdr) {
      return axiosACN
        .post(
          '/commodity/doValidatePdr',
          {
            pdr: pdr
          },
          {
            headers: {
              Authorization: 'Bearer ' + rootGetters['session/token']
            }
          }
        )
        .then((res) => {
          return res.data.status === 'OK'
        })
        .catch((err) => {
          if (err.statusCode && err.statusCode >= 400) {
            throw Error('SERVER_ERROR')
          }
        })
    },
    getPayPalUrl({ rootGetters }) {
      const iframeBaseUrl = window.location.origin
      return axiosACN
        .post(
          '/payment/V2/retrieveUrlPaypal',
          {
            urlBack: window.btoa(iframeBaseUrl + '/paypal/back.html'),
            urlDone: window.btoa(iframeBaseUrl + '/paypal/done.html')
          },
          {
            headers: {
              Authorization: 'Bearer ' + rootGetters['session/token']
            }
          }
        )
        .then((res) => {
          return window.atob(res.data.urlRedirect)
        })
        .catch((err) => {
          if (err.statusCode && err.statusCode >= 400) {
            throw Error('SERVER_ERROR')
          }
        })
    },
    getListino({ commit, rootGetters }, commodityType) {
      // TODO: Da cambiare con il nuovo servizio
      return axiosACN
        .post(
          '/commodity/V2/getListinoRes',
          {
            tipoContratto: commodityType,
            tipoFunnel: 'APP_OFFERTACASA'
          },
          {
            headers: {
              Authorization: 'Bearer ' + rootGetters['session/token']
            }
          }
        )
        .then((res) => {
          let result = res.data.result
          let resFormated = {
            24: {
              prezzo:
                result['NE24'].prezzoConPerditeLuce ||
                result['NE24'].prezzoGas ||
                0,
              prezzoConPerdite: result['NE24'].prezzoConPerditeLuce || null,
              quotaFissa:
                result['NE24'].quotaFissaLuce ||
                result['NE24'].quotaFissaGas ||
                0,
              listino:
                result['NE24'].listinoLuce || result['NE24'].listinoGas || '',
              versione:
                result['NE24'].versioneLuce || result['NE24'].versioneGas || '',
              dataFineValidita:
                parseFloat(result['NE24'].dataFineValidita) || null,
              docName:
                result['NE24'].docNameLuce || result['NE24'].docNameGas || null
            },
            12: {
              prezzo:
                result['NE12'].prezzoConPerditeLuce ||
                result['NE12'].prezzoGas ||
                0,
              prezzoConPerdite: result['NE12'].prezzoConPerditeLuce || null,
              quotaFissa:
                result['NE12'].quotaFissaLuce ||
                result['NE12'].quotaFissaGas ||
                0,
              listino:
                result['NE12'].listinoLuce || result['NE12'].listinoGas || '',
              versione:
                result['NE12'].versioneLuce || result['NE12'].versioneGas || '',
              dataFineValidita:
                parseFloat(result['NE12'].dataFineValidita) || null,
              docName:
                result['NE12'].docNameLuce || result['NE12'].docNameGas || null
            }
          }
          return resFormated
        })
        .catch((err) => {
          if (err.statusCode && err.statusCode >= 400) {
            throw Error('SERVER_ERROR')
          }
        })
    },
    getExportPreventivi({ commit, rootGetters }, params) {
      return axiosACN
        .post('/commodity/V3/exportPreventiviRes', params, {
          headers: {
            Authorization: 'Bearer ' + rootGetters['session/token']
          }
        })
        .then((res) => {
          let result = res.data.result
          let resFormated = {
            24: {
              annualCost:
                result['NE24'].costoAnnualeEle ||
                result['NE24'].costoAnnualeGas ||
                0,
              monthtlyCost:
                result['NE24'].costoMensileEle ||
                result['NE24'].costoMensileGas ||
                0,
              annualSaving: result['NE24'].risparmioAnnuale || 0,
              monthlySaving: result['NE24'].risparmioMensile || 0,
              consumptionVolumes:
                result['NE24'].volumeConsumoEleAnnuale ||
                result['NE24'].volumeConsumoGasAnnuale ||
                ''
            },
            12: {
              annualCost:
                result['NE12'].costoAnnualeEle ||
                result['NE12'].costoAnnualeGas ||
                0,
              monthtlyCost:
                result['NE12'].costoMensileEle ||
                result['NE12'].costoMensileGas ||
                0,
              annualSaving: result['NE12'].risparmioAnnuale || 0,
              monthlySaving: result['NE12'].risparmioMensile || 0,
              consumptionVolumes:
                result['NE12'].volumeConsumoEleAnnuale ||
                result['NE12'].volumeConsumoGasAnnuale ||
                ''
            }
          }
          return resFormated
        })
        .catch((err) => {
          if (err.statusCode && err.statusCode >= 400) {
            throw Error('SERVER_ERROR')
          }
        })
    },
    subscribe({ commit, getters, rootGetters }) {
      const creditCardCircuit = getters.paymentMethod.creditCardCircuit
      let params = {
        completo: true,
        listino: getters.getInfoUpselling.catalogList[0].tariffList, // TODO -> Da cambiare --> M
        idTariffList: getters.getIdTariffList, // --> M CAMPO DA MODIFICARE IN BASE AL MODE DATO DAL BACKEND
        flagDiniegoMarketing: getters.client.marketingConsentFlag, // --> O
        flagDiniegoProfilazione: getters.client.profilingConsentFlag, // --> O
        flagSospensioneImposta: false, // --> O
        flagAttivazioneAnticipata: getters.supplyDetail.anticipateActivation, // TODO -> Da visionare --> O
        flagRecesso: getters.typeRequest === 'CA' ? getters.consens : false, // --> O
        tipoContratto: getters.commodityType.toUpperCase(), // --> O
        codiceAmico: getters.mgmDetail?.promoCode || '', // --> O
        listinoMgm: getters.mgmDetail?.listinoMgm || '', // --> O
        codiceCampagnaMgm: getters.mgmDetail?.nomeCampagnaMgm || '', // --> O
        cliente: {
          codiceCliente: getters.address.clientOwnerCode, // --> O
          username: getters.client.username, // --> M
          codiceFiscale: getters.client.fiscalCode, // --> M
          nome: getters.client.firstName, // --> M
          cognome: getters.client.lastName, // --> M
          email: getters.client.contactMail, // --> M
          telefono:
            getters.client.mobilePhone || getters.client.phone || '0000000000', // --> O
          indirizzo: {
            via: getters.address.address || '', // --> M
            cap: getters.address.zip || '', // --> M
            comune: getters.address.comune || '', // --> M
            frazione: getters.address.egonLocality || '', // TODO -> Da visionare --> O
            civico: getters.address.streetNumber || '', // --> M
            idEgonCivico: getters.address.egonStreetNumber || '', // --> M
            provincia: getters.address.provincia || '', // --> M
            regione: getters.address.egonRegion || '', // --> O
            paese: 'Italia', // --> O
            idEgonVia: getters.address.egonStreet || '', // --> M
            esponente: getters.address.egonStreetNumberLetter || '', // --> O
            idEgonComune: getters.address.comuneEgon || '' // TODO -> Da visionare --> O
          }
        },
        datiPagamento: {
          tipoPagamento: getters.paymentMethod.methodType, // --> M
          alias: getters.paymentMethod.aliasPan || '', // --> O
          crecurr: getters.paymentMethod.crecurr || '', // --> O
          circuit: Object.keys(Consts.CREDIT_CARDS_CIRCUITS).includes(
            creditCardCircuit
          )
            ? Consts.CREDIT_CARDS_CIRCUITS[creditCardCircuit]
            : creditCardCircuit || '', // --> O
          creditCard:
            (getters.paymentMethod.creditCardNumber &&
              getters.paymentMethod.creditCardNumber.replace(/\*/g, '')) ||
            '', // --> O
          expiredDateCreditCard:
            getters.paymentMethod.creditCardExpirationDate &&
            // getters.paymentMethod.creditCardExpirationDate.replace(
            //   /\//g,
            //   ''
            // )
            getters.paymentMethod.creditCardExpirationDate.includes('/')
              ? getters.paymentMethod.creditCardExpirationDate
                  .split('/')[1]
                  .substring(2) +
                getters.paymentMethod.creditCardExpirationDate.split('/')[0]
              : getters.paymentMethod.creditCardExpirationDate
              ? getters.paymentMethod.creditCardExpirationDate
              : '', // --> O
          sottoscrittoreCognome: !getters.paymentMethod.sameHolder
            ? getters.paymentMethod.ibanSubscriberLastName || ''
            : undefined, // --> O
          sottoscrittoreNome: !getters.paymentMethod.sameHolder
            ? getters.paymentMethod.ibanSubscriberFirstName || ''
            : undefined, // --> O
          codiceFiscale: !getters.paymentMethod.sameHolder
            ? getters.paymentMethod.ibanSubscriberFiscalCode || ''
            : undefined, // --> O
          codiceIban: getters.paymentMethod.iban || '', // --> O
          flagTitolareConto: getters.paymentMethod.sameHolder // --> O
        }
      }
      if (getters.commodityType === 'ele') {
        params.ele = {
          attualeFornitore: getters.supplyDetail.oldProvider?.ID_SOCIETA || '', // TODO -> Da visionare --> O
          consumiAnnuo: getters.supplyDetail.ele.consumption.toString() || '', // --> M
          potenzaImpegnata:
            getters.supplyDetail.ele.power === 'O'
              ? 'A'
              : getters.supplyDetail.ele.power || '', // --> M
          potenzaImpegnataAltro:
            getters.supplyDetail.ele.alternativePower.toString() || undefined, // --> O
          titolaritaImmobile: getters.supplyDetail.ownership || '', // --> M
          tipoUso: getters.supplyDetail.ele.isRes ? 'DO' : 'AU', // --> M
          indirizzoFornitura: {
            via: getters.address.address || '', // --> M
            cap: getters.address.zip || '', // --> M
            comune: getters.address.comune || '', // --> M
            frazione: getters.address.egonLocality || '', // TODO -> Da visionare --> O
            civico: getters.address.streetNumber || '', // --> M
            idEgonCivico: getters.address.egonStreetNumber || '', // --> M
            provincia: getters.address.provincia || '', // --> M
            regione: getters.address.egonRegion || '', // --> O
            paese: 'Italia', // --> O
            idEgonVia: getters.address.egonStreet || '', // --> M
            esponente: getters.address.egonStreetNumberLetter || '', // --> O
            idEgonComune: getters.address.comuneEgon || '' // TODO -> Da visionare --> O
          },
          residente: getters.supplyDetail.isResident || '', // --> M
          tipoRichiesta: getters.typeRequest, // --> M
          tensione:
            getters.typeRequest !== 'CA'
              ? getters.supplyDetail.ele.tension
              : undefined, // --> O
          disalimentabile:
            getters.typeRequest !== 'CA'
              ? getters.supplyDetail.isUnpluggable
              : undefined, // --> O
          categoria:
            getters.typeRequest !== 'CA'
              ? !getters.supplyDetail.isUnpluggable
                ? 'A'
                : ''
              : undefined, // --> O
          tipologiaRiattivazione:
            getters.typeRequest === 'CO'
              ? getters.supplyDetail.ele.powerChanged // TODO -> Da visionare
                ? 'PI'
                : 'VP'
              : '', // --> O
          pod:
            getters.typeRequest !== 'PR'
              ? getters.supplyDetail.ele.pod
              : undefined // --> O
        }
      } else {
        params.gas = {
          consumiAnnuo: getters.supplyDetail.gas.consumption || '', // --> M
          costoMedioBolletta: getters.supplyDetail.gas.avgBillCost || '', // --> O
          titolaritaImmobile: getters.supplyDetail.ownership || '', // --> M
          tipoUso: getUseTypeGas(getters.supplyDetail.gas), // --> M
          indirizzoFornitura: {
            via: getters.address.address || '', // --> M
            cap: getters.address.zip || '', // --> M
            comune: getters.address.comune || '', // --> M
            frazione: getters.address.egonLocality || '', // TODO -> Da visionare --> O
            civico: getters.address.streetNumber || '', // --> M
            idEgonCivico: getters.address.egonStreetNumber || '', // --> M
            provincia: getters.address.provincia || '', // --> M
            regione: getters.address.egonRegion || '', // --> O
            paese: 'Italia', // --> O
            idEgonVia: getters.address.egonStreet || '', // --> M
            esponente: getters.address.egonStreetNumberLetter || '', // --> O
            idEgonComune: getters.address.comuneEgon || '' // TODO -> Da visionare --> O
          },
          residente: getters.supplyDetail.isResident || '', // --> O
          tipoRichiesta: getters.typeRequest, // --> M
          attualeFornitore: getters.supplyDetail.oldProvider?.ID_SOCIETA || '', // TODO -> Da visionare --> O
          remi:
            getters.typeRequest !== 'PR'
              ? getters.supplyDetail.gas.remi
              : undefined, // --> O
          pdr:
            getters.typeRequest !== 'PR'
              ? getters.supplyDetail.gas.pdr
              : undefined // --> O
        }
      }
      return axiosACN
        .post(
          `/fornitura/V2/${
            getters.commodityType === 'ele'
              ? 'upsellingEleRes'
              : 'upsellingGasRes'
          }`,
          params,
          {
            headers: {
              Authorization: 'Bearer ' + rootGetters['session/token']
            }
          }
        )
        .then((res) => {
          // Manage business logic errors (coming with status 200 OK + errorcode)
          if (res.data.status === 'KO') {
            GenErr.showErrorMessage()
            throw Error('UNKNOWN_ERROR')
          }
          if (res.data.errorCode) {
            // No bus logic errors expected
            throw Error('UNKNOWN_ERROR')
          }
          commit('setSupplyDetailRoot', {
            contractCode: res.data.result.codiceProposta
          })
          return res.status
        })
        .catch((err) => {
          GenErr.showErrorMessage()
          if (err.statusCode && err.statusCode >= 400) {
            throw Error('SERVER_ERROR')
          } else if (err.message) {
            throw err
          }
        })
    }
  }
}
